<template>
  <div style="background-color: #FAFAFA;padding-top: 25px;padding-bottom: 110px">
    <div class="center" style="overflow: hidden">
      <!--左边区域-->
      <div style="margin-right: 20px;width: 900px;float: left">
        <div class="eop-main">
          <List item-layout="vertical" :split="false" :loading="knowledgeListLoading">
            <ListItem v-for="(item,index) in knowledgeList" :key="index" style="padding: 0">
              <router-link :to="{path: 'knowledgeDetail',query:{'id':item.id}}" target="_blank" class="eop-list">
                <img :src="item.imageUrl" class="eopl-img" />
                <div class="eopl-des">
                  <div class="eopl-des-title">
                    <div class="eopl-des-title-text ellipse-1">{{ item.title }}</div>
                    <div class="eopl-des-title-time">{{ item.createdTime }}</div>
                  </div>
                  <div class="eopl-des-int ellipse-2">
                    {{item.introduction}}
                  </div>
                </div>
              </router-link>
            </ListItem>
          </List>
        </div>


        <div style="overflow: hidden;width: 850px">
          <Page @on-change="onPageChange" :current="pageNum" :page-size="10" :total="knowledgeListTotalNum"
                show-elevator
                style="margin-top: 30px;float: right;margin-bottom: 30px" />
        </div>
      </div>

      <patent-component />

    </div>
  </div>
</template>

<script>

import {
  getKnowledgeListById,
  getKnowledgeType
} from "../../plugins/api/encyclopediaKnowledgeApi";
import newestPatentAndAchievement from "./newestPatentAndAchievement";

export default {
  name: "SciencePolicy",
  data() {
    return {
      // nowIndex: 1,
      typeList: [],//上方标签列表
      currentTypeId: -1,//当前选中的标签ID
      knowledgeList: [],//左边知识列表
      knowledgeListLoading: true,//左边知识列表是否在加载中loading
      knowledgeListTotalNum: 0,//左边知识列表总共有多少条数据
      pageNum: 1//分页当前页

    };
  },
  components: {
    patentComponent: newestPatentAndAchievement
  },
  mounted() {
    this.getTypeList();
  },
  methods: {
    //切换头部标签
    changeTypeId(id) {
      this.currentTypeId = id;
      this.pageNum = 1;
      this.knowledgeListTotalNum = 0;
      this.getKnowledgeListById(id);
    },
    //获取分类标签列表
    async getTypeList() {
      const json = await getKnowledgeType().catch(() => this.knowledgeListLoading = false);
      if (json && json.code === 0) {
        if (json.result && json.result.list && json.result.list.length > 0) {
          this.typeList = json.result.list;

          const tab = this.$route.query.tab;
          if (tab) {
            this.currentTypeId = json.result.list[tab - 1].id;
          } else {
            this.currentTypeId = json.result.list[1].id;
          }
          this.getKnowledgeListById(this.currentTypeId);
        }
      }
      this.knowledgeListLoading = false;
    },
    //根据文章分类编号获取文章列表
    async getKnowledgeListById(articleTypeId) {
      this.knowledgeListLoading = true;
      let params = {
        pageSize: 10,
        pageNum: this.pageNum,
        articleTypeId: articleTypeId
      };
      const json = await getKnowledgeListById(params).catch(() => this.knowledgeListLoading = false);
      if (json && json.code === 0) {
        if (json.result && json.result.list) {
          this.knowledgeList = json.result.list;
          this.knowledgeListTotalNum = json.result.total;
        }
      }
      this.knowledgeListLoading = false;
    },
    //分页器改变时的回调
    onPageChange(pageNum) {
      this.pageNum = pageNum;
      this.getKnowledgeListById(this.currentTypeId);
    }
  }
};
</script>

<style scoped lang="scss">

/deep/ .ivu-page-item-active {
  background-color: #fc7c40;
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}


.center {
  width: 1200px;
  margin: 0 auto;
}

.eop-main {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.eop-list {
  height: 125px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px dashed rgba(229, 232, 237, 1);
  padding-top: 20px;
  padding-bottom: 20px;
}

.eopl-img {
  width: 120px;
  height: 90px;
  border-radius: 5px;
}

.eopl-des {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;
  overflow: hidden;
}

.eopl-des-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.eopl-des-title-text {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.eopl-des-title-time {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-left: 15px;
  flex-shrink: 0;
}

.eopl-des-int{
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
}
</style>
